div{
    font-family: 'Raleway', sans-serif;
}

#home_sidebar{
    position: relative;
    background-color: #0C0354;
    padding: 0;
    height: 100vh;
}

#home_page_background{
    position: absolute;
    width: 100%;
    height: 100vh;
}