body {
	font-family: "Open Sans", sans-serif;
	color: #0c0c0c;
	background-color: #ffffff;
	overflow-x: hidden;
}
/*header section*/
.hero_area {
	position: relative;
	min-height: 100vh;

	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	top: -20px;
}
.ReactModal__Overlay {
	z-index: 1000;
}
.hero_area .hero_bg_box {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	overflow: hidden;
	z-index: -1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.locationSitePhotosStatus {
	position: absolute;
	top: 120px;
	transform: rotate(-63.944deg);
	text-align: center;
	font-family: Inter;
	font-size: 60px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
}

.hero_area .hero_bg_box .bg_img_box {
	min-width: 100%;
	min-height: 100%;
}

.hero_area .hero_bg_box img {
	min-width: 100%;
	min-height: 100%;
}
.header_section {
	padding: 15px 0;
}

/* .header_section .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }
   */
.navbar-brand span {
	font-weight: bold;
	font-size: 24px;
	color: #ffffff;
}

.slider_section {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	/* padding-bottom: 128px; */
	padding: 16px 0 80px 0;
}

/* .slider_section .row {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
} */

.slider_section #customCarousel1 {
	width: 100%;
	position: unset;
}

.slider_section .detail-box {
	color: #00204a;
}

.slider_section .detail-box h1 {
	font-size: 3rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 15px;
	color: #ffffff;
}
.slider_section .detail-box h3 {
	margin-bottom: 64px;
}

.slider_section .detail-box p {
	color: #fefefe;
	font-size: 14px;
}

.slider_section .detail-box .btn-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0 -5px;
	margin-top: 25px;
}

.slider_section .detail-box .btn-box a {
	margin: 5px;
	text-align: center;
	width: 165px;
}

.slider_section .detail-box .btn-box .btn1 {
	display: inline-block;
	padding: 10px 15px;
	background-color: #00bbf0;
	color: #ffffff;
	border-radius: 0;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border: none;
}

.slider_section .detail-box .btn-box .btn1:hover {
	background-color: #007fa4;
}

.slider_section .detail-box .btn-box .btn2 {
	display: inline-block;
	padding: 10px 15px;
	background-color: #000000;
	color: #ffffff;
	border-radius: 0;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border: none;
}

.slider_section .detail-box .btn-box .btn2:hover {
	background-color: black;
}

.slider_section .img-box img {
	width: 100%;
	/* -webkit-animation: upDown 5s infinite;
            animation: upDown 5s infinite; */
}
input[type="file"] {
	display: none;
}
.image-input-holder {
	padding: 14px 14px;
	color: grey;
	margin-top: 16px;
	margin-bottom: 16px;
	max-height: 12rem;
	width: 50%;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;
	cursor: pointer;
}
.image-input-holder:hover {
	border: solid 1px black;
}

#insert_image {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}

.my-actions {
	margin: 2em 2em 0;
}

.order-1 {
	order: 1;
}

.order-2 {
	order: 2;
}

.order-3 {
	order: 3;
}

.right-gap {
	margin-right: auto;
}
.header_section .container-fluid.card {
	padding: unset !important;
}
.navbar-brand {
	margin: unset;
}

.image-display-holder {
	display: flex;
	justify-content: center;
	padding: 6px;
	background-color: #2829a6;
	border-radius: 12px;
}

.image-display {
	width: 60px;
	height: 60px;
	border-radius: 12px;
}
.down-arrow {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 30px solid #2829a6;
	transform: translate(0, -15%);
}

#sign_up_btn:disabled {
	background-color: gainsboro;
	pointer-events: none;
}

@-webkit-keyframes upDown {
	0% {
		-webkit-transform: translateY(-45px);
		transform: translateY(-45px);
	}
	50% {
		-webkit-transform: translateY(45px);
		transform: translateY(45px);
	}
	100% {
		-webkit-transform: translateY(-45px);
		transform: translateY(-45px);
	}
}

@keyframes upDown {
	0% {
		-webkit-transform: translateY(-45px);
		transform: translateY(-45px);
	}
	50% {
		-webkit-transform: translateY(45px);
		transform: translateY(45px);
	}
	100% {
		-webkit-transform: translateY(-45px);
		transform: translateY(-45px);
	}
}

@media only screen and (min-width: 0px) {
	.home-header-img {
		height: 80px;
	}
}

@media only screen and (min-width: 1200px) {
	.home-header-img {
		height: 144px;
	}
}
